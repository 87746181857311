// Browser polyfills
// IE9, IE10 and IE11 requires all of the following polyfills
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

// used instead of es6-shim
import 'core-js/client/shim.min';

// IE10 and IE11 requires the following for the Reflect API
import 'core-js/es6/reflect';

// IE10 and IE11 requires the following for NgClass support on SVG elements
import 'classlist.js';

// IE10 and IE11 requires the following to support `@angular/animation`
import 'web-animations-js';

// zone JS is required by Angular itself
import 'zone.js/dist/zone';

// Application polyfills
// date, currency, decimal and percent pipes
import 'intl';
import 'intl/locale-data/jsonp/en.js';

// environments
if (process.env.NODE_ENV === 'production') {
  // production and demo environments
} else {
  // development and test environments
  Error['stackTraceLimit'] = Infinity;
  require('zone.js/dist/long-stack-trace-zone');
}
