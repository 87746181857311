"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Browser polyfills
// IE9, IE10 and IE11 requires all of the following polyfills
require("core-js/es6/symbol");
require("core-js/es6/object");
require("core-js/es6/function");
require("core-js/es6/parse-int");
require("core-js/es6/parse-float");
require("core-js/es6/number");
require("core-js/es6/math");
require("core-js/es6/string");
require("core-js/es6/date");
require("core-js/es6/array");
require("core-js/es6/regexp");
require("core-js/es6/map");
require("core-js/es6/weak-map");
require("core-js/es6/set");
// used instead of es6-shim
require("core-js/client/shim.min");
// IE10 and IE11 requires the following for the Reflect API
require("core-js/es6/reflect");
// IE10 and IE11 requires the following for NgClass support on SVG elements
require("classlist.js");
// IE10 and IE11 requires the following to support `@angular/animation`
require("web-animations-js");
// zone JS is required by Angular itself
require("zone.js/dist/zone");
// Application polyfills
// date, currency, decimal and percent pipes
require("intl");
require("intl/locale-data/jsonp/en.js");
// environments
if (process.env.NODE_ENV === 'production') {
    // production and demo environments
}
else {
    // development and test environments
    Error['stackTraceLimit'] = Infinity;
    require('zone.js/dist/long-stack-trace-zone');
}
